<template>
  <div
    v-if="loading"
    class="d-flex justify-center"
  >
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>
  <div v-else>
    <!-- the card title of the page -->
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="12"
        order="4"
        order-md="3"
        class="align-self-end mb-sm-2 mt-2"
      >
        <v-card
          class="ps-3 mx-1 rounded-xl"
          flat
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
              class="py-0"
            >
              <v-card-title class="page-title flex-nowrap text-2xl mt-0 pt-2 px-1 align-center">
                <div class="d-flex align-center">
                  <v-avatar
                    size="40"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      size="25"
                      color="primary"
                    >
                      mdi-phone
                    </v-icon>
                  </v-avatar>
                  <span class="text-no-wrap font-weight-bold mx-1">
                    {{ $t('CallTemplate') }}
                  </span>
                </div>
              </v-card-title>
              <v-card-text class="pt-1 pb-0">
                <v-row
                  v-for="(item, index) in $t('CallTemplatesExplain')"
                  :key="index"
                >
                  <div class="pb-1 d-flex">
                    <div>
                      <v-icon
                        class="me-1"
                        color="primary"
                        size="25"
                      >
                        {{ titleIcons[index] }}
                      </v-icon>
                    </div>
                    <div>
                      <span>{{ item }}</span>
                    </div>
                  </div>
                </v-row>
                <v-row>
                  <div class="d-flex pb-1">
                    <div>
                      <v-icon

                        color="primary"
                      >
                        {{ icons.mdiPlayOutline }}
                      </v-icon>
                    </div>
                    <div>
                      <span>
                        {{ $t('ListenCall') }}

                        <v-avatar
                          size="26"
                          class="v-avatar-light-bg primary--text"
                        >
                          <v-icon
                            size="18"
                            color="primary"
                          >
                            {{ icons.mdiVolumeHigh }}
                          </v-icon>
                        </v-avatar>
                      </span>
                    </div>
                  </div>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <div>
                <v-img
                  width="140"
                  src="@/assets/images/3d-characters/pose-fs-9.png"
                  class="gamification-john-pose-2"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Call template -->
    <v-row
      justify="center"
      align="center"
      class="mt-10"
    >
      <v-col
        cols="12"
        md="7"
        sm="8"
      >
        <v-card
          v-show="Object.keys(langMsg || {}).length > 0"
          class="ma-5 mx-auto mt-0 rounded-xl"
          tile
          flat
        >
          <v-tabs
            v-model="currentTabLang"
            show-arrows
            center-active
            mobile-breakpoint="1"
            fixed-tabs
            centered
          >
            <v-tab
              v-for="(value, index) in langMsg"
              :key="index"
            >
              {{ $t(value.lang) }}
            </v-tab>
          </v-tabs>
          <v-row
            align="center"
            justify="center"
            class="mt-4"
          >
            <v-col
              cols="12"
              md="8"
              lg="8"
              sm="10"
            >
              <v-tabs-items
                v-model="currentTabLang"
              >
                <v-tab-item
                  v-for="(msg, index) in langMsg"
                  :key="index"
                  class="justify-center align-center pa-2"
                >
                  <v-textarea
                    v-model="msg.message"
                    outlined
                    auto-grow
                    :maxlength="150"
                    rounded
                    counter
                    :rules="[validators.maxCallLenMessage, validators.minLenMessage, validators.required]"
                    style="white-space: pre-line;"
                    dir="auto"
                    height="260px"
                  >
                    <template #append>
                      <v-btn
                        icon
                        @click="$store.state.activeCall ? endPlayback() : playAudio(msg)"
                      >
                        <v-avatar
                          size="35"
                          class="v-avatar-light-bg primary--text"
                        >
                          <v-icon
                            size="23"
                            :class="$store.state.activeCall ? 'volumeIcon' : ''"
                            :color="$store.state.activeCall ? 'secondary' : 'primary'"
                          >
                            {{ icons.mdiVolumeHigh }}
                          </v-icon>
                        </v-avatar>
                      </v-btn>
                    </template>
                  </v-textarea>

                  <v-card-actions
                    class="justify-center align-center mt-3 pb-0"
                  >
                    <v-btn
                      color="primary"
                      class="me-2"
                      rounded
                      large
                      @click="saveMsg(msg)"
                    >
                      {{ $t('Saving') }}
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- Iphone display  -->
      <v-col
        cols="12"
        md="3"
        sm="4"
        style="display:flex;"
      >
        <phone-call-preview
          :message="activeCallMessage"
          :audio-player="audioPlayer"
        ></phone-call-preview>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import PhoneCallPreview from '@/components/EventDetails/PhoneCallPreview.vue'
// eslint-disable-next-line import/no-cycle
import useCallPreviewAudioPlayer from '@/composables/useCallPreviewAudioPlayer'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { maxCallLenMessage, minLenMessage, required } from '@core/utils/validation'
import {
  mdiCommentEditOutline, mdiHeartOutline, mdiPlayOutline, mdiVolumeHigh,
} from '@mdi/js'
import { computed, ref, onUnmounted } from '@vue/composition-api'
import Vue from 'vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    PhoneCallPreview,
  },

  setup() {
    const currentTabLang = ref(-1)
    const langMsg = ref()
    const audioPlayer = useCallPreviewAudioPlayer()
    const { playAudiosSequentially, endPlayback } = audioPlayer
    const toast = useToast()
    const showToast = msg => toast.error(i18n.t(msg))
    const loading = ref(true)

    const titleIcons = [mdiHeartOutline, mdiCommentEditOutline]

    onUnmounted(() => {
      endPlayback()
    })

    const getCallMessages = () => {
      loading.value = true
      store
        .dispatch('getVoiceMessagesTemplate', { id: store.state.eventData.id })
        .then(response => {
          const messagesArray = response.data
          const mappedMessages = messagesArray.reduce(
            (carry, val) => ({
              ...carry,
              [val.lang]: {
                lang: val.lang,
                message: val.message,
              },
            }),
            {},
          )

          langMsg.value = mappedMessages
          currentTabLang.value += Object.keys(langMsg.value || {}).length
        })
        .catch(() => showToast('errorManageEvents'))
        .finally(() => {
          loading.value = false
        })
    }
    getCallMessages()

    const playAudio = msg => {
      loading.value = true
      if (msg.message.length < 20) {
        loading.value = false
        showToast(i18n.t('MinField', { num: '20' }))
      } else {
        store
          .dispatch('textToSpeech', {
            id: store.state.eventData.id,
            callTemplate: { lang: msg.lang, text: msg.message },
          })
          .then(res => {
            loading.value = false
            playAudiosSequentially(res.data)
          })
          .catch(() => {
            endPlayback()
            showToast('errorManageEvents')
          })
          .finally(() => {
            loading.value = false
          })
      }
    }

    const saveMsg = item => {
      loading.value = true
      if (item.message.length < 20) {
        loading.value = false
        showToast(i18n.t('MinField', { num: '20' }))
      } else {
        const userCallMessages = {
          templates: [{ lang: item.lang, message: item.message, type: 4 }],
        }

        store
          .dispatch('editVoiceMessagesTemplate', { id: store.state.eventData.id, callTemplate: userCallMessages })
          .then(() => {
            Vue.prototype.$gtag.event('Call_Template_Changed', {
              event_category: 'call template',
              event_label: 'Template Edit Success',
            })
          })
          .catch(() => showToast('errorManageEvents'))
          .finally(() => {
            loading.value = false
          })
      }
    }
    const activeCallMessage = computed(() => {
      if (Object.keys(langMsg.value || {}).length === 0) {
        return ''
      }

      return langMsg.value[Object.keys(langMsg.value)[currentTabLang.value]]
    })

    return {
      currentTabLang,
      langMsg,
      saveMsg,
      playAudio,
      endPlayback,
      loading,
      activeCallMessage,
      titleIcons,
      audioPlayer,

      icons: {
        mdiVolumeHigh,
        mdiPlayOutline,
      },
      validators: {
        maxCallLenMessage,
        minLenMessage,
        required,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.gamification-john-pose-2 {
  position: absolute;
}

.gamification-john-pose-2 {
  bottom: 0;
  @include ltr() {
    right: 5%;
  }
  @include rtl() {
    left: 5%;
  }
}
@media (max-width: 800px) {
  .gamification-john-pose-2 {
    max-width: 100px;
  }
}

@media (max-width: 600px) {
  .gamification-john-pose-2 {
    display: none;
  }
}

@media (max-width: 400px) {
  .page-title {
    font-size: 1.2rem !important;
  }
}

.volumeIcon {
  animation: zoomIn 2s both infinite;
}
@keyframes zoomIn {
  from {
    transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
  }
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
::v-deep.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding-right: 10px;
  padding-left: 10px;
}
::v-deep.v-text-field .v-input__append-inner {
  margin-top: 5px;
}
</style>
